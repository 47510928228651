import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { associateLogout } from 'store/actions/app'
import './Header.scss'

import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
	Segment,
} from 'semantic-ui-react'

const getAvatar = (associate) => {
	let name = 'Guest'
	if (associate) {
		name = associate?.code
	}
	return `https://ui-avatars.com/api/?name=${name}`
}

const AssociateHeader = (props) => {
	const {
		associate,
		associateLogout,
		setExpand,
		openModal,
		setOpenModal,
		mobileOnly,
		previousExpand,
		setPreviousExpand,
	} = props

	const [modalVisible, setModalVisible] = useState(false)

	let userName = associate?.name ?? ''

	return (
		<Menu
			className='NavBar__Header'
			inverted
			borderless
			size='tiny'
			attached='top'
			fixed='top'
		>
			<Menu className='SubMenu__Header' size='mini'>
				<Menu.Item className='NavBar__Item'>
					<Icon
						className='NavBar__Outline'
						name='list'
						size='large'
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setExpand((previousExpandedState) => {
								setPreviousExpand(previousExpandedState)
								if (!mobileOnly)
									return previousExpandedState == 1
										? previousExpand == null || previousExpand == 2
											? 0
											: 2
										: 1
								return !previousExpandedState ? 1 : 0
							})
						}}
					/>
				</Menu.Item>
				<Menu.Item>
					<Header>
						<h4 className='NavBar__Outline'>Geologistic ERP</h4>
						<Header.Subheader className='NavBar__Outline'>
							V 0.17.70
						</Header.Subheader>
					</Header>
				</Menu.Item>
				<Menu.Item fitted position='right'>
					<Icon
						className='Circle_Icon NavBar__Item'
						name='question'
						circular
						onClick={() => {
							setOpenModal(!openModal)
						}}
					/>
				</Menu.Item>
			</Menu>
			<Menu.Menu position={mobileOnly ? null : 'right'} size='tiny'>
				<Menu.Item fitted position='right' className='NavBar__Item'>
					<Popup
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						pinned
						on='click'
						trigger={
							<Menu.Item className='NavBar__Item'>
								<Image src={getAvatar(associate)} avatar />
							</Menu.Item>
						}
						content={
							<Segment className='NavBar__Outline'>
								<Grid
									divided='vertically'
									columns='equal'
									className='Header_PopUp'
									style={{ display: 'inline-block', padding: 0 }}
								>
									<Grid.Row columns={1}>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Image
												className='Header_PopUp_Image'
												src={getAvatar(associate)}
												avatar
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<h4 className='NavBar__InvertedOutline'>
												{userName.toUpperCase()}
											</h4>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Button
												className='Header_PopUp_Button'
												basic
												icon
												labelPosition='right'
												onClick={() => {
													associateLogout()
													props.history.push('/login_superadmin');
												}}
											>
												<Icon name='sign out' />
												Cerrar sesión
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						}
						position='bottom right'
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	associateLogout: () => dispatch(associateLogout()),
})

const mapStateToProps = (state) => ({
	associate: state.app?.superAdmin?.associate,
})

export default connect(mapStateToProps, mapDispatchToProps)(AssociateHeader)
